import {configure, computed, spy, makeObservable} from 'mobx';
import React from 'react';

// import analytics from 'analytics.js';
import autobind from 'common/decorators/autobind.js';
import {getCopyData} from 'core/referrals.js';
import {PRACTICE_LOC_CONTACT_TYPE} from 'core/constants.js';

import PracticeStoreV2 from 'practices/stores/PracticeStoreV2.js';
import {trackOpenPracticeLocation} from '../services/internalEventTracking';

// Throws if observable is changed outside of action
configure({enforceActions: 'observed'});

export default class PracticeLocationStoreV2 extends PracticeStoreV2 {
    constructor(rootStore) {
        // TODO: [mobx-undecorate] verify the constructor arguments and the arguments of this automatically generated super call
        super();

        this.rootStore = rootStore;

        makeObservable(this, {
            contactProps: computed,
            header: computed,
            locationSectionChipsData: computed,
            relatedFactsheets: computed,
            hasFees: computed,
            // This is necessary for skinned view where the practice has practitioners
            // but none of them have active gap scheme participation
            hasPractitioners: computed,
            gapSchemePractitioners: computed,
            nonGapSchemePractitioners: computed,
            faxContactProps: computed,
            shouldShowLocationsTab: computed,
        });
    }

    // static member
    internalTrackingEvent = {
        faxClick: {
            event: 'practiceLocationFaxClick',
        },
        visitSite: {
            event: 'openPracticeLocationWebsite',
        },
    };

    get hasFees() {
        return false;
    }

    get shouldShowLocationsTab() {
        return true;
    }
    get header() {
        return {
            avatar: this.practice.avatar,
            amountOfLocations: this.practice.amountOfLocations,
            languagesSpoken: this.practice.languagesSpoken,
            streetAddress: this.practice.streetAddress,
            name: this.practice.name,
            practiceGroupLink: this.practice.practiceGroupLink,
            locality: this.practice.locality,
            phones:
                this.practice.phones?.length > 0
                    ? {
                          numbers: this.practice.phones,
                      }
                    : undefined,
            fax: this.practice.fax
                ? {
                      ...this.practice.fax,
                  }
                : undefined,
            website: this.practice.website
                ? {
                      analyticsEventData: {
                          eventName: 'openPracticeLocationWebsite',
                          data: {
                              'practice_location': this.practice.id,
                          },
                      },
                      href: this.practice.website,
                  }
                : undefined,
        };
    }
    get contactProps() {
        const {practice} = this;
        if (this.practiceLoaded && practice.phones?.length > 0) {
            return {
                contactId: practice.id,
                contactName: practice.name,
                contactType: PRACTICE_LOC_CONTACT_TYPE,
                isEnhanced: practice.enhanced,
                items: [
                    {
                        address: `${practice.streetAddress}, ${practice.locality}`,
                        id: practice.id,
                        name: practice.name,
                        phones: practice.phones,
                        type: PRACTICE_LOC_CONTACT_TYPE,
                    },
                ],
            };
        }
        return null;
    }

    get locationSectionChipsData() {
        const practice = this.locationSectionActiveItem;
        return {
            practiceLocationAddress: `${practice?.streetAddress?.trim()}${
                practice?.streetAddress?.trim()
                    ? practice?.locality && ', ' + practice?.locality
                    : practice?.locality
            }`,
            phones: practice?.phones,
            fax: practice?.fax,
            externalUrl: practice?.website,
        };
    }

    get relatedFactsheets() {
        return (
            this.practiceRelatedContentLoaded &&
            this.practiceRelatedContent.relatedFactsheets
        );
    }

    get gapSchemePractitioners() {
        const {
            gapSchemeMembership,
            practice: {practitioners},
        } = this;
        const gapSchemes = gapSchemeMembership
            .filter((pr) => pr.hasGapScheme === true)
            .map((pr) => pr.id);
        return practitioners?.filter((pr) =>
            gapSchemes.includes(pr.userprofileId),
        );
    }

    get nonGapSchemePractitioners() {
        const {
            gapSchemeMembership,
            practice: {practitioners},
        } = this;
        const nonGapSchemes = gapSchemeMembership
            .filter((pr) => pr.hasGapScheme === false)
            .map((pr) => pr.id);
        return practitioners?.filter((pr) =>
            nonGapSchemes.includes(pr.userprofileId),
        );
    }

    get hasPractitioners() {
        const {
            practice: {practitioners},
            rootStore: {
                healthFundStore: {healthFund},
            },
        } = this;

        return healthFund?.id
            ? !!this.gapSchemePractitioners?.length ||
                  !!this.nonGapSchemePractitioners?.length
            : !!practitioners?.length;
    }
    get faxContactProps() {
        const {practice} = this;
        if (this.practiceLoaded && practice.fax?.number !== '') {
            return {
                contactId: practice.id,
                contactName: practice.name,
                contactType: PRACTICE_LOC_CONTACT_TYPE,
                isEnhanced: practice.enhanced,
                items: [
                    {
                        address: `${practice.streetAddress}, ${practice.locality}`,
                        id: practice.id,
                        name: practice.name,
                        phones: [{number: `${practice.fax?.number}`}],
                        type: PRACTICE_LOC_CONTACT_TYPE,
                    },
                ],
            };
        }
        return null;
    }

    getRequestApptModalContent = (practicePosition) => {
        const {
            avatar,
            address,
            displayName,
            id,
            specialties,
        } = practicePosition;
        const {
            practice: {localityId, localitySuburb},
        } = this;
        return {
            address,
            avatar,
            displayName,
            displayScreenerQuestion: this.isSpecialist(practicePosition),
            id,
            specialties,
            localityId,
            localitySb: localitySuburb,
        };
    };

    getContactModalContent = () => {
        const {
            contactProps,
            rootStore: {
                paramStore: {hasSkin},
                healthFundStore: {healthFundThemeColor},
            },
        } = this;
        const colorTheme =
            hasSkin && healthFundThemeColor ? healthFundThemeColor : null;
        return {
            ...contactProps,
            colorTheme,
            hasSkin,
            number: this.header.phones?.numbers[0],
        };
    };
    // Extracts data from an available practice and adds a profile detail if provided
    @autobind
    getCopyData(profileName, additionalProps) {
        return getCopyData(profileName, {
            ...this.practice,
            ...additionalProps,
        });
    }

    @autobind
    throwFetchPracticeError(error) {
        throw new Error(`Practice Location API returned: ${error}`);
    }

    trackPageOpen() {
        trackOpenPracticeLocation(this.practice);
    }

    fetchOpenFileData(fileId) {
        if (fileId) {
            return {
                eventName: 'openPracticeLocationFile',
                data: {
                    'practice_location_file': fileId,
                },
            };
        } else {
            this.throwError(
                'Missing required file id for tracking openPracticeLocationFile',
            );
        }
    }
}
